'use strict';

const cache = {
    $body: $('body'),
    $modalContent: $('.js-modal-content')
}

function handleRes(res) {
    $('#locale-modal').toggle();
    location.reload();
}

module.exports = function () {
    cache.$body.on('click', '.btn-verify', function () {
        const form = $('#locale-form');
        const url = form.data('action');

        cache.$modalContent.spinner().start();
        $.ajax({
            url,
            type: 'post',
            data: form.serialize(),
            success(res) {
                handleRes(res);
            },
            error() {
                cache.$modalContent.spinner().stop();
                handleRes(res);
            },
        });
    }).on('click', '#country-change', function () {
        $('#locale-modal').toggle();
    });
}
