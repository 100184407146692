'use strict';

const cache = {
    $window: $(window),
    $body: $('body'),
}

function trackingAddToCart(data) {
    var totalPrice = 0;
    const currency = data.cart.totals.currency;

    const items = data.products.map(function (product) {
        const item = {
            item_id: product.id.slice(2),
            item_name: product.productName,
            discount: product.price.list ? (product.price.list.value - product.price.sales.value) : 0,
            currency: product.price.sales.currency,
            item_category: product.primaryCategory.parentCategory,
            item_category2: product.primaryCategory.name,
            price: product.price.list ? product.price.list.value : product.price.sales.value,
            quantity: product.selectedQuantity,
        };

        if (product.promotions && product.promotions.length) {
            item.promotion_id = product.promotions[0].id;
            item.promotion_name = product.promotions[0].name;
        }

        return item;
    });

    if (items && items.length > 0) items.forEach(item => totalPrice += (item.price - item.discount) * item.quantity);

    cache.$body.trigger('Tracking:AddProduct', { totalPrice, items, currency, data })
}

function trackingRemoveToCart(data) {
    const totalPrice = data.basket.totals.subTotalPrice;
    const currency = data.basket.totals.currency;
    const product = data.product;
    const cartItemsRemaining = data.productToPush;
    const items = [
        {
            item_id: product.id.slice(2),
            item_name: product.productName,
            discount: product.price.list ? (product.price.list.value - product.price.sales.value) : 0,
            currency: product.price.sales.currency,
            item_category: product.primaryCategory.parentCategory,
            item_category2: product.primaryCategory.name,
            price: product.price.list ? product.price.list.value : product.price.sales.value,
            quantity: product.selectedQuantity,
        },
    ];

    if (product.promotions && product.promotions.length) {
        items[0].promotion_id = product.promotions[0].id;
        items[0].promotion_name = product.promotions[0].name;
    }

    cache.$body.trigger('Tracking:RemoveProduct', { totalPrice, items, currency, cartItemsRemaining });
}

function trackingAddToWishlist(data) {
    const product = data.product;

    const items = [
        {
            item_id: product.id.slice(2),
            item_name: product.productName,
            discount: product.price.list ? (product.price.list.value - product.price.sales.value) : 0,
            currency: product.price.sales.currency,
            item_category: product.primaryCategory.parentCategory,
            item_category2: product.primaryCategory.name,
            price: product.price.list ? product.price.list.value : product.price.sales.value,
            quantity: 1,
        },
    ];

    if (product.promotions && product.promotions.length) {
        items[0].promotion_id = product.promotions[0].id;
        items[0].promotion_name = product.promotions[0].name;
    }

    cache.$body.trigger('Tracking:AddToWishlist', { items });
}

function trackingLogin(event, userID) {
    cache.$body.trigger('Tracking:Login', { event, method: 'form', userID });
}

function trackingRegistration(data) {
    const method = data.method;
    const customer_type = data.customer_type;
    const event = data.event;

    cache.$body.trigger('Tracking:Registration', { event, method, customer_type });
}

function trackingPromotion(data) {
    cache.$body.trigger('Tracking:AppliedCoupon', { coupon: data.coupon });
}

function trackingDownloadCatalog($target) {
    const form_title = $target.attr('title');

    cache.$body.trigger('Tracking:DownloadCatalog', form_title);
}

module.exports = function () {
    cache.$window.on('resize', (e) => cache.$body.trigger('windowResize', e));
    cache.$window.on('scroll', (e) => cache.$body.trigger('windowScroll', e));

    cache.$body
        .on('product:afterAddToCart', function (e, context) {
            if (!context.error)
                trackingAddToCart(context);
        })
        .on('product:AfterRemoveFromCart', function (e, context) {
            if (!context.error)
                trackingRemoveToCart(context);
        })
        .on('product:AddToWishlist', function (e, context) {
            if (!context.error)
                trackingAddToWishlist(context);
        })
        .on('login:success', function (e, context) {
            trackingLogin('user_login', context.userID);
        })
        .on('checkoutLogin:success', function (e, context) {
            trackingLogin('checkout_user_login', context.userID);
        })
        .on('registration:success', function (e, context) {
            trackingRegistration(context);
        })
        .on('promotion:success', function (e, context) {
            trackingPromotion(context);
        })
        .on('click', '.js-download', function () {
            trackingDownloadCatalog($(this));
        });
}
