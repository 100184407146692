'use strict';

// var base = require('base_bofrost/main');

window.jQuery = window.$ = require('jquery');
const processInclude = require('base/util');

$(document).ready(() => {
    processInclude(require('base_bofrost/main'));
    processInclude(require('./customDropdown'));
    processInclude(require('base_bofrost/countrySelector'));
    processInclude(require('./components/changeLocale'));
    processInclude(require('./eventListener'));
    processInclude(require('./components/trackingEvents'));
});
